import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";
import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import mushroom from "cem-probe-api";
// import Maska from "maska";
import { createPinia } from "pinia";
const pinia = createPinia();

import Mapbox from "mapbox-gl";
import VueFeather from "vue-feather";
import Particles from "particles.vue3";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "@/assets/scss/config/default/app.scss";
import "@vueform/slider/themes/default.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import toastr from "toastr";

import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/h5/gridstack-dd-native";

import rate from "vue-rate";
import "vue-rate/dist/vue-rate.css";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

window.toastr = require("toastr");
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "3000",
  timeOut: "2000",
  extendedTimeOut: "1000",
};

mushroom._on("$auth.loggedout", (info) => {
  if (info?.source == "fromOtherTab") {
    // người dùng đã log out ở tab khác
    // thực hiện chuyển sang trang login
    location.href = "/session-time-out";
  }
});
mushroom._on("$auth.loggedin", (token, rootUrl, info) => {
  if (info?.source == "fromOtherTab") {
    // người dùng đã log in ở tab khác
    // thực hiện chuyển sang trang chủ
    location.href = "/home";
  }
});

const getProbeName = async () => {
  let bodyData = {
    probe_id: localStorage.pid,
  };
  try {
    const response = await mushroom.probe.getProbeInformationAsync(
      {
        body: bodyData,
      },
      { enabledCache: false }
    );
    localStorage.setItem("probeName", response.result.name);
  } catch (error) {
    console.error("Có lỗi: %o", error);
  }
};
getProbeName();

let checkStatusToken = setInterval(statusToken, 20000);
async function statusToken() {
  const response = await mushroom.$auth.statusAsync();
  if (response.result.status === "guest") {
    toastr.warning("Tài khoản đã bị đăng xuất");
    setTimeout(() => {
      router.push({ name: "session-time-out" });
      clearInterval(checkStatusToken);
    }, 2000);
  }
}
checkStatusToken;

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  // .use(Maska)
  .use(Particles)
  .use(i18n)
  .use(ElementPlus)
  .use(rate)
  .use(pinia)
  .use(Mapbox)
  .use(vClickOutside)
  .mount("#app");

import { datePicker } from "/src/stores/datePicker";
const storeDatePicker = datePicker();

// window.addEventListener("beforeunload", function () {
//   localStorage.removeItem("dateSelect");
// });

async function getDateProbe() {
  if (localStorage.getItem("dateSelect")) {
    let dates = localStorage.getItem("dateSelect");
    let d = dates.split(",");
    let startDate = new Date(d[0]).toISOString();
    let stopDate = new Date(d[1]).toISOString();
    storeDatePicker.setDatePicker([startDate, stopDate]);
  } else {
    const responseTimeProbe = await mushroom.probe_data.getDefaultDateAsync(
      {
        body: { probe_id: localStorage.pid },
      },
      { enabledCache: false }
    );
    if (responseTimeProbe?.result) {
      storeDatePicker.setDatePicker([
        responseTimeProbe.result,
        responseTimeProbe.result,
      ]);
    } else {
      storeDatePicker.setDatePicker([
        new Date().toISOString(),
        new Date().toISOString(),
      ]);
    }
  }
}

getDateProbe();
