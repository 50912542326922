import { createWebHistory, createRouter } from "vue-router";
import mushroom from "cem-probe-api";

import routes from "./routes";
import appConfig from "../../app.config";
import { isHaveDataDashboard } from "/src/stores/HaveDataDashboard";
import { timeRange } from "/src/stores/timeRange";
import { ref } from "vue";
const timeRangeNumber = ref(0);
const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});
const getDayLimit = async (name, id) => {
  if (!id) return;
  timeRangeNumber.value = 0;
  let type = "static";
  if (name === "DichVu") type = "service";
  if (name === "TableScreen") type = "table_screen";
  if (name === "Dynamic") type = "dynamic";
  if (name.indexOf("Usecase") !== -1) type = "usecase";

  try {
    const response = await mushroom.time_range.listAsync(
      {
        filters:
          type === "static"
            ? ["target_type=static", "link=" + location.pathname]
            : ["target_type=" + type, "target_id=" + id],
        limit: 1,
      },
      { enabledCache: false }
    );
    if (response.result?.length === 0) {
      const timeRangeSystemConfig = await mushroom.system_config.listAsync({
        filters: ["key=time_range"],
        limit: 1,
      });
      if (timeRangeSystemConfig.result?.length > 0) {
        timeRangeNumber.value = timeRangeSystemConfig.result[0]?.value ?? 0;
      }
    } else {
      timeRangeNumber.value = response.result[0]?.day_limit ?? 0;
    }
    timeRangeNumber.value = Number(timeRangeNumber.value); // chuyển sang số vì trong system_config là str
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
// location.replace("/")
// const storeTimeRange = timeRange();
router.beforeEach(async function (to, from, next) {
  const storeIsHaveDataDashboard = isHaveDataDashboard();
  const storeTimeRange = timeRange();
  if (from.params.id !== to.params.id) {
    await getDayLimit(to.name, to.params.id);
    storeTimeRange.setTimeRange(timeRangeNumber.value);
  }

  if (from.name != to.name) {
    if (to.meta.page && to.meta.page == "admin") {
      // lưu đường dẫn link web hiện tại vào stograte để sau login lại
      next();
      setTimeout(async () => {
        await getDayLimit(to.name, to.params.id);
        storeTimeRange.setTimeRange(timeRangeNumber.value);
      }, 700);

      setTimeout(() => {
        localStorage.setItem("urlOld", window.location.href);
      }, 200);

      clearTimeout(window.checkStatusDownloadProbe); // xoá check download dashboard
      storeIsHaveDataDashboard.noData();
    } else if (to.meta.page && to.meta.page != "admin") {
      next();
      setTimeout(async () => {
        await getDayLimit(to.name, to.params.id);
        storeTimeRange.setTimeRange(timeRangeNumber.value);
      }, 700);
    } else {
      next("/error");
    }
  } else if (from.name && to.name && from.name == to.name) {
    next();
  } else {
    if (!to.name) {
      next("/error");
    } else next("/session-time-out");
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + " | " + appConfig.title;
  next();
});

export default router;
